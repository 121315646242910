<template>
  <div>
    <v-row>
      <v-col cols="12">
        <Crud
          title="Gestão de Clientes"
          buttonAddItemText="Novo cliente"
          :showButtonAddItem="$can('create', 'Cliente')"
          searchLabelText="Pesquisar na lista"
          :searchTextField.sync="searchTextField"
          :formTitle="nameForm"
          :callBackModalCancelar="fechar"
          :dialog.sync="dialog"
          :callBackModalSalvar="salvar"
          :dialogDelete.sync="dialogDelete"
        >
          <template slot="data-content">
            <v-row class="mt-5">
              <!-- FILTRO CAMPOS -->
            </v-row>
          </template>
          <template slot="addButtonsArea">
            <v-btn
              color="secondary"
              outlined
              class="mb-4 ml-3"
              large
              @click="abreModalImportarArquivo()"
            >
              <v-icon size="17" class="me-1"> mdi-tray-arrow-down </v-icon>
              <span>Importar</span>
            </v-btn>
          </template>
          <template slot="data-table">
            <v-data-table
              :headers="headers"
              :items="clientes"
              class="elevation-5"
              :loading="loadings.loadingTable"
            >
              <!-- ACTIONS -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <!-- Visualizar -->
                  <v-list v-if="$can('view', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="toggleModalLateral(item)"
                              >
                                mdi-eye
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Visualizar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <!-- Editar -->
                  <v-list v-if="$can('edit', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon class="actions" @click="editar(item)">
                                mdi-pencil
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Editar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>

                  <!-- Deletar -->
                  <v-list v-if="$can('delete', 'Cliente')">
                    <v-list-item>
                      <v-list-item-title>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon
                                class="actions"
                                @click="remover(item, true)"
                              >
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Deletar</span>
                        </v-tooltip>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-switch
                  class="mt-0 pt-0"
                  v-model="item.status"
                  @click="mudarStatus(item)"
                  :label="item.status == 'A' ? 'Ativo' : 'Inativo'"
                  color="success"
                  true-value="A"
                  false-value="I"
                  hide-details
                ></v-switch>
              </template>
              <template v-slot:[`item.nome`]="{ item }">
                <div class="d-flex d-row align-center">
                  <v-avatar v-if="item.logotipo" class="mr-2" size="36px">
                    <v-img
                      :src="renderImage(item.logotipo)"
                      :alt="`logotipo de ${item.nome}`"
                    ></v-img>
                  </v-avatar>
                  <span>
                    {{ item.nome }}
                  </span>
                </div>
              </template>
              <template v-slot:[`item.created_at`]="{ item }">
                {{ item.created_at | dateFormatBr() }}
              </template>
            </v-data-table>
          </template>
          <!-- ------------- MODAL DE CADASTRO --------------------------------------------- -->
          <template slot="contentModalCrud">
            <Formulario />
          </template>
          <!--------------- MODAL DE DELETE --------------------------------------------- -->
          <template slot="modalDelete/titleModal">
            Excluir o registro?
          </template>
          <template slot="modalDelete/contentModal">
            <p>
              Atenção! Você está prestes a realizar uma ação que não pode ser
              desfeita.
            </p>
            <p>Você realmente deseja excluir esse registro?</p>
          </template>
          <template slot="modalDelete/actionsModal">
            <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
            <v-btn
              :loading="loadings.loadingDelete"
              color="error"
              @click="remover()"
            >
              Excluir
            </v-btn>
            <v-spacer class="d-none d-md-block"></v-spacer>
          </template>
        </Crud>
      </v-col>
    </v-row>
    <ModalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="headerModalLateral"
      :item="modalLateral.item"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="getColor(item.status)" small>
          {{ item.status == "A" ? "Ativo" : "Inativo" }}
        </v-chip>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}h</span
        >
      </template>
      <template v-slot:[`item.abertura`]="{ item }">
        <span>
          {{ item.abertura | dateFormatBr() }}
        </span>
      </template>
      <template v-slot:[`item.celular`]="{ item }">
        <span v-if="item.celular">
          {{ item.celular | maskForCel() }}
        </span>
      </template>
      <template v-slot:[`item.telefone`]="{ item }">
        <span v-if="item.telefone">
          {{ item.telefone | maskForTel() }}
        </span>
      </template>
      <template v-slot:[`item.cnpj`]="{ item }">
        <span>
          {{ item.cnpj | cnpjMask() }}
        </span>
      </template>
      <template v-slot:[`item.logotipo`]="{ item }">
        <v-avatar size="120">
          <img
            v-if="item.logotipo"
            style="height: auto; max-height: 200px; max-width: 200px"
            class="py-3"
            :src="item.logotipo"
            :alt="item.nome + '_logotipo'"
          />
        </v-avatar>
      </template>
    </ModalLateral>
    <v-dialog v-model="dialogImport" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Importar lista de clientes
        </v-card-title>

        <v-card-text>
          Para importar a lista de seus clientes faça o download da tabela de
          exemplo abaixo, preencha com os dados solicitados e cole no campo
          abaixo.
        </v-card-text>
        <v-card-text>
          <v-file-input
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            label="Arquivo de importação"
            v-model="dialogArquivo"
            hide-details
            outlined
            dense
          ></v-file-input>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="dialogLoading"
            @click="fechaModalImportacao()"
            class="ma-1"
            color="error"
            plain
          >
            Fechar
          </v-btn>

          <v-btn
            class="ma-1"
            color="grey"
            plain
            @click="enviarModalImportação()"
            :loading="dialogLoading"
          >
            Importar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loadings.loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";
import Formulario from "../modules/Formulario.vue";
import { numbersOnly } from "@/helpers";

export default {
  name: "ClientesView",
  inject: ["$validator"],
  components: {
    Crud,
    ModalLateral,
    Formulario,
  },
  data: () => ({
    logotipo: logo,
    nameForm: "Novo cliente",
    loadings: {
      loading: false,
      loadingTable: false,
      loadingDelete: false,
    },
    searchTextField: "",
    dialog: false,
    dialogImport: false,
    dialogLoading: false,
    dialogArquivo: [],
    indexEdicao: -1,
    headers: [
      { text: "Nome", value: "nome" },
      { text: "E-mail", value: "email" },
      { text: "Data de criação", value: "created_at" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    headerModalLateral: [
      { text: "", value: "logotipo" },
      { text: "E-mail", value: "email" },
      { text: "Razão social", value: "razao_social" },
      { text: "Cnpj", value: "cnpj" },
      { text: "Inscrição estadual", value: "inscricao_estadual" },
      { text: "Inscrição municipal", value: "inscricao_municipal" },
      { text: "Celular", value: "celular" },
      { text: "Telefone", value: "telefone" },
      { text: "Descrição", value: "descricao" },
      { text: "Abertura", value: "abertura" },
      { text: "Data de criação", value: "created_at" },
      { text: "Data de atualização", value: "updated_at" },
      { text: "Status", value: "status" },
    ],
    modalLateral: {
      show: false,
      item: [],
      title: "",
    },

    dialogDelete: false,
    item: [],
  }),

  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === -1 ? "Cadastrar cliente" : "Editar cliente";
    },
    cliente: {
      get() {
        return this.$store.getters["clientes/getCliente"];
      },
    },
    clientes: {
      get() {
        return this.$store.getters["clientes/getClientes"];
      },
    },
  },

  async created() {
    this.loadings.loading = true;

    await this.$store.dispatch("clientes/initialize");

    this.loadings.loading = false;
  },

  methods: {
    //CRUD
    salvar() {
      this.$validator.validate("form_client.*").then((result) => {
        if (result) {
          this.dialog = false;
          this.loadings.loadingTable = true;
          this.indexEdicao = this.clientes.findIndex(
            (el) => el.id == this.cliente.id
          );

          let url =
            this.indexEdicao === -1
              ? "/clientes"
              : "/clientes/" + this.cliente.uuid;
          let method = this.indexEdicao === -1 ? "POST" : "PUT";

          let sucesso =
            this.indexEdicao === -1
              ? "Cliente cadastrado com sucesso."
              : "Cliente atualizado com sucesso.";
          let erro =
            this.indexEdicao === -1
              ? "Erro ao salvar cliente."
              : "Erro ao atualizar cliente.";

          const formData = this.formRequest();
          formData.append("_method", method);

          const request = {
            data: formData,
            _method: method,
            url: url,
          };

          this.$store
            .dispatch("clientes/save", request)
            .then((response) => {
              if (response != "NAO_AUTORIZADO") {
                this.indexEdicao === -1
                  ? this.clientes.push(response)
                  : Object.assign(
                      this.clientes[this.indexEdicao],
                      this.cliente
                    );

                this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                  visibilidade: true,
                  mensagem: sucesso,
                  cor: "green",
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
                visibilidade: true,
                mensagem: erro,
                cor: "red",
              });
            })
            .finally(() => {
              this.fechar();
            });
        }
      });
    },
    /**
     * @description Cria e organiza o formData de forma com a necessidade dos dados.
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    formRequest() {
      const formData = new FormData();
      this.cliente.id ? formData.append("id", this.cliente.id) : null;
      this.cliente.uuid ? formData.append("uuid", this.cliente.uuid) : null;
      formData.append("nome", this.cliente.nome);
      this.cliente.email ? formData.append("email", this.cliente.email) : null;
      this.cliente.razao_social
        ? formData.append("razao_social", this.cliente.razao_social)
        : null;
      this.cliente.inscricao_estadual
        ? formData.append("inscricao_estadual", this.cliente.inscricao_estadual)
        : null;
      this.cliente.inscricao_municipal
        ? formData.append(
            "inscricao_municipal",
            this.cliente.inscricao_municipal
          )
        : null;
      this.cliente.celular
        ? formData.append("celular", numbersOnly(this.cliente.celular))
        : null;
      this.cliente.telefone
        ? formData.append("telefone", numbersOnly(this.cliente.telefone))
        : null;
      this.cliente.cnpj
        ? formData.append("cnpj", numbersOnly(this.cliente.cnpj))
        : null;
      this.cliente.abertura
        ? formData.append("abertura", this.cliente.abertura)
        : null;
      this.cliente.descricao
        ? formData.append("descricao", this.cliente.descricao)
        : null;
      this.cliente.logotipo
        ? formData.append("logotipo", this.cliente.logotipo)
        : null;
      this.cliente.status
        ? formData.append("status", this.cliente.status)
        : null;

      return formData;
    },
    editar(item) {
      this.loadings.loadingTable = true;

      this.$store
        .dispatch("clientes/findByUuid", item.uuid)
        .then(() => {
          this.nameForm = "Editar cliente";
          this.dialog = true;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro ao buscar todos os dados do cliente.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    fechar() {
      this.nameForm = "Novo cliente";
      this.indexEdicao = -1;
      this.dialog = false;
      this.loadings.loadingTable = false;

      this.$store.dispatch("clientes/resetCliente");
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = Object.assign({}, item);
        this.dialogDelete = true;
      } else {
        this.loadings.loadingDelete = true;
        const indexItem = this.clientes.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("clientes/remove", this.item.uuid)
          .then((response) => {
            if (response) {
              this.clientes.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Cliente removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            this.$store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o cliente.",
            });
          })
          .finally(() => {
            this.dialogDelete = false;
            this.loadings.loadingDelete = false;
          });
      }
    },
    mudarStatus(item) {
      this.loadings.loadingTable = true;

      const request = {
        data: {
          id: item.id,
          status: item.status,
          _method: "PUT",
        },
        _method: "PUT",
        url: `/clientes/${item.uuid}`,
      };

      this.$store
        .dispatch("clientes/save", request)
        .then((response) => {
          if (response != "NAO_AUTORIZADO") {
            this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
              visibilidade: true,
              mensagem: "Cliente atualizado com sucesso.",
              cor: "green",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Erro ao atualizar cliente.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    toggleModalLateral(item) {
      if (this.modalLateral.show) return (this.modalLateral.show = false);

      this.loadings.loadingTable = true;

      this.$store
        .dispatch("clientes/findByUuid", item.uuid)
        .then((response) => {
          this.modalLateral.show = true;
          this.modalLateral.title = response.nome;
          this.modalLateral.item = response;
        })
        .catch(() => {
          this.$store.dispatch("snackbar/ativarSnackbarGlobal", {
            visibilidade: true,
            mensagem: "Houve um erro ao buscar todos os dados do cliente.",
            cor: "red",
          });
        })
        .finally(() => {
          this.loadings.loadingTable = false;
        });
    },
    //DATATABLE COSTUMIZATION
    getColor(status) {
      if (status == "A") return "success";
      else return "secondary";
    },
    /**
     * @description Retorna a imagem caso ela já seja uma string, senão cria uma url para a mesma
     *
     * @param - Object|String image
     *
     * @author Matheus Eduardo França <matheusefranca1727@gmail.com>
     */
    renderImage(image) {
      if (typeof image == "string") return image;

      return URL.createObjectURL(image);
    },
    abreModalImportarArquivo() {
      this.dialogImport = !this.dialogImport;
      this.dialogLoading = false;
    },
    fechaModalImportacao() {
      this.dialogImport = !this.dialogImport;
      this.dialogLoading = false;
    },
    enviarModalImportação() {
      this.dialogLoading = true;
      const formData = new FormData();
      formData.append("arquivo", this.dialogArquivo);

      this.$store
        .dispatch("clientes/enviarAquivoImportacaoClientes", formData)
        .then((response) => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "success",
            timeout: 3000,
            text: "Importação realizada com sucesso",
          });
        })
        .catch(() => {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Houve um erro, tente mais tarde.",
          });
        })
        .finally(() => {
          this.dialogLoading = false;
          this.dialogImport = !this.dialogImport;
        });
    },
  },
};
</script>
