import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('Crud',{attrs:{"title":"Gestão de Clientes","buttonAddItemText":"Novo cliente","showButtonAddItem":_vm.$can('create', 'Cliente'),"searchLabelText":"Pesquisar na lista","searchTextField":_vm.searchTextField,"formTitle":_vm.nameForm,"callBackModalCancelar":_vm.fechar,"dialog":_vm.dialog,"callBackModalSalvar":_vm.salvar,"dialogDelete":_vm.dialogDelete},on:{"update:searchTextField":function($event){_vm.searchTextField=$event},"update:search-text-field":function($event){_vm.searchTextField=$event},"update:dialog":function($event){_vm.dialog=$event},"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event}}},[_c('template',{slot:"data-content"},[_c(VRow,{staticClass:"mt-5"})],1),_c('template',{slot:"addButtonsArea"},[_c(VBtn,{staticClass:"mb-4 ml-3",attrs:{"color":"secondary","outlined":"","large":""},on:{"click":function($event){return _vm.abreModalImportarArquivo()}}},[_c(VIcon,{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" mdi-tray-arrow-down ")]),_c('span',[_vm._v("Importar")])],1)],1),_c('template',{slot:"data-table"},[_c(VDataTable,{staticClass:"elevation-5",attrs:{"headers":_vm.headers,"items":_vm.clientes,"loading":_vm.loadings.loadingTable},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VMenu,{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[(_vm.$can('view', 'Cliente'))?_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.toggleModalLateral(item)}}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar")])])],1)],1)],1):_vm._e(),(_vm.$can('edit', 'Cliente'))?_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.editar(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)],1)],1):_vm._e(),(_vm.$can('delete', 'Cliente'))?_c(VList,[_c(VListItem,[_c(VListItemTitle,[_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c(VIcon,{staticClass:"actions",on:{"click":function($event){return _vm.remover(item, true)}}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Deletar")])])],1)],1)],1):_vm._e()],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VSwitch,{staticClass:"mt-0 pt-0",attrs:{"label":item.status == 'A' ? 'Ativo' : 'Inativo',"color":"success","true-value":"A","false-value":"I","hide-details":""},on:{"click":function($event){return _vm.mudarStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex d-row align-center"},[(item.logotipo)?_c(VAvatar,{staticClass:"mr-2",attrs:{"size":"36px"}},[_c(VImg,{attrs:{"src":_vm.renderImage(item.logotipo),"alt":("logotipo de " + (item.nome))}})],1):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.nome)+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.created_at))+" ")]}}],null,true)})],1),_c('template',{slot:"contentModalCrud"},[_c('Formulario')],1),_c('template',{slot:"modalDelete/titleModal"},[_vm._v(" Excluir o registro? ")]),_c('template',{slot:"modalDelete/contentModal"},[_c('p',[_vm._v(" Atenção! Você está prestes a realizar uma ação que não pode ser desfeita. ")]),_c('p',[_vm._v("Você realmente deseja excluir esse registro?")])]),_c('template',{slot:"modalDelete/actionsModal"},[_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Fechar")]),_c(VBtn,{attrs:{"loading":_vm.loadings.loadingDelete,"color":"error"},on:{"click":function($event){return _vm.remover()}}},[_vm._v(" Excluir ")]),_c(VSpacer,{staticClass:"d-none d-md-block"})],1)],2)],1)],1),_c('ModalLateral',{attrs:{"show":_vm.modalLateral.show,"hide":_vm.toggleModalLateral,"title":_vm.modalLateral.title,"headers":_vm.headerModalLateral,"item":_vm.modalLateral.item},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{attrs:{"color":_vm.getColor(item.status),"small":""}},[_vm._v(" "+_vm._s(item.status == "A" ? "Ativo" : "Inativo")+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.created_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.created_at))+"h")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("dateFormatBr")(item.updated_at))+" "+_vm._s(_vm._f("timeFormatBr")(item.updated_at))+"h")])]}},{key:"item.abertura",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("dateFormatBr")(item.abertura))+" ")])]}},{key:"item.celular",fn:function(ref){
var item = ref.item;
return [(item.celular)?_c('span',[_vm._v(" "+_vm._s(_vm._f("maskForCel")(item.celular))+" ")]):_vm._e()]}},{key:"item.telefone",fn:function(ref){
var item = ref.item;
return [(item.telefone)?_c('span',[_vm._v(" "+_vm._s(_vm._f("maskForTel")(item.telefone))+" ")]):_vm._e()]}},{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("cnpjMask")(item.cnpj))+" ")])]}},{key:"item.logotipo",fn:function(ref){
var item = ref.item;
return [_c(VAvatar,{attrs:{"size":"120"}},[(item.logotipo)?_c('img',{staticClass:"py-3",staticStyle:{"height":"auto","max-height":"200px","max-width":"200px"},attrs:{"src":item.logotipo,"alt":item.nome + '_logotipo'}}):_vm._e()])]}}],null,true)}),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.dialogImport),callback:function ($$v) {_vm.dialogImport=$$v},expression:"dialogImport"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Importar lista de clientes ")]),_c(VCardText,[_vm._v(" Para importar a lista de seus clientes faça o download da tabela de exemplo abaixo, preencha com os dados solicitados e cole no campo abaixo. ")]),_c(VCardText,[_c(VFileInput,{attrs:{"accept":".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel","label":"Arquivo de importação","hide-details":"","outlined":"","dense":""},model:{value:(_vm.dialogArquivo),callback:function ($$v) {_vm.dialogArquivo=$$v},expression:"dialogArquivo"}})],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{staticClass:"ma-1",attrs:{"disabled":_vm.dialogLoading,"color":"error","plain":""},on:{"click":function($event){return _vm.fechaModalImportacao()}}},[_vm._v(" Fechar ")]),_c(VBtn,{staticClass:"ma-1",attrs:{"color":"grey","plain":"","loading":_vm.dialogLoading},on:{"click":function($event){_vm.enviarModalImportação()}}},[_vm._v(" Importar ")])],1)],1)],1),_c(VOverlay,{attrs:{"value":_vm.loadings.loading}},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"#001325","size":"75"}},[_c('img',{attrs:{"src":_vm.logotipo,"height":"45","alt":""}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }