<template>
  <v-container fluid>
    <v-form id="formCliente">
      <v-row class="ma-3" wrap>
        <!-- logotipo -->
        <v-col cols="12" sm="12" md="2">
          <div class="d-flex flex-row align-center justify-center">
            <v-hover v-slot="{ hover }">
              <v-avatar size="78">
                <v-overlay absolute :value="hover">
                  <div class="d-flex align-center justify-center">
                    <v-file-input
                      v-show="hover"
                      class="primary--text pa-0 ma-0"
                      hide-details
                      hide-input
                      hide-spin-buttons
                      v-model="cliente.logotipo"
                      accept="image/*"
                      prepend-icon="mdi-camera"
                      type="file"
                    >
                    </v-file-input>
                  </div>
                </v-overlay>

                <v-img :src="viewerLogotipo" contain></v-img>
              </v-avatar>
            </v-hover>
          </div>
        </v-col>
        <!-- nome -->
        <v-col cols="12" sm="12" md="10">
          <v-text-field
            class="white"
            hide-details="true"
            label="Nome"
            outlined
            v-model.trim="cliente.nome"
            data-vv-as="nome"
            persistent-hint
            :hint="errors.first('form_client.nome')"
            :error="errors.collect('form_client.nome').length ? true : false"
            data-vv-scope="form_client"
            v-validate="'required|min:3'"
            data-vv-validate-on="change|blur"
            id="nome"
            name="nome"
          >
          </v-text-field>
        </v-col>
        <!-- e-mail -->
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="E-mail"
            outlined
            v-model.trim="cliente.email"
            data-vv-as="e-mail"
            persistent-hint
            id="email"
            name="email"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Razão social"
            outlined
            v-model.trim="cliente.razao_social"
            data-vv-as="Razão social"
            persistent-hint
            id="razao_social"
            name="razao_social"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Cnpj"
            outlined
            v-model="cliente.cnpj"
            data-vv-as="Cnpj"
            persistent-hint
            id="cnpj"
            name="cnpj"
            v-mask="'##.###.###/####-##'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Inscrição estadual"
            outlined
            v-model="cliente.inscricao_estadual"
            type="number"
            data-vv-as="inscricao_estadual"
            persistent-hint
            id="inscricao_estadual"
            name="inscricao_estadual"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Inscrição municipal"
            outlined
            v-model="cliente.inscricao_municipal"
            type="number"
            data-vv-as="inscricao_municipal"
            persistent-hint
            id="inscricao_municipal"
            name="inscricao_municipal"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Celular"
            outlined
            v-model="cliente.celular"
            data-vv-as="celular"
            persistent-hint
            id="celular"
            name="celular"
            v-mask="['(##) #####-####']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-text-field
            class="white"
            hide-details="true"
            label="Telefone"
            outlined
            v-model="cliente.telefone"
            data-vv-as="telefone"
            persistent-hint
            id="telefone"
            name="telefone"
            v-mask="['(##) ####-####']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                v-model="aberturaConverted"
                label="Data de abertura"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="cliente.abertura"
              min="1950-01-01"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <v-textarea
            v-model.trim="cliente.descricao"
            outlined
            label="Descrição"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import userNotImage from "@/assets/images/avatars/user_not_image.png";
import { getCurrentyDatePtBr } from "@/helpers/index.js";

export default {
  name: "FormularioClienteView",
  inject: ["$validator"],
  data() {
    return {
      // Dados do Date Picker
      date: null,
      menu: false,
    };
  },
  computed: {
    cliente: {
      get() {
        return this.$store.getters["clientes/getCliente"];
      },
    },
    viewerLogotipo() {
      if (!this.cliente.logotipo) return userNotImage;

      if (typeof this.cliente.logotipo == "object") {
        return URL.createObjectURL(this.cliente.logotipo);
      }

      return this.cliente.logotipo;
    },
    aberturaConverted() {
      if (!this.cliente.abertura) return null;

      return getCurrentyDatePtBr(this.cliente.abertura);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>

<style scoped>
.mdi-camera::before {
  content: "\F39A";
  color: blue;
}
</style>
